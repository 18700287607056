//Window resize

window.onresize = function() {
    navigation.nav.style.transition = '0s';
    setTimeout(function(){
      navigation.nav.style.transition = '0.6s';
    },300);
  waitForFinalEvent(function(){

  },100,'resize');
}

class Navigation {
  constructor() {
    this.button = $('.header__toggle');
    this.nav = $('.primary');
    this.header = $('.header');
    this.button.addEventListener('click', () => {
      this.toggle();
    });
  }

  toggle() {
    if (this.header.hasAttribute('data-visible')) {
      this.header.removeAttribute('data-visible');
      document.querySelector('body').removeAttribute('data-noscroll');
    } else {
      this.header.setAttribute('data-visible', '');
      document.querySelector('body').setAttribute('data-noscroll','');
    }
  }

}

class View{
	constructor(){
		this.refresh();
		window.addEventListener('resize',()=>{
			if (this.get()!==this.media) {
        this.refresh();
      }
		});
	}
	get(){
		return window.getComputedStyle(document.body,':before').getPropertyValue('content').replace(/['"]+/g,'');
	}
	refresh(){
		this.media=this.get();
	}
}

class Scroll {
  constructor() {
    this.header = $('.header');
    window.addEventListener('scroll', (e) => {
      if (window.scrollY !== 0) {
        this.header.setAttribute('data-scrolled','');
      } else {
        this.header.removeAttribute('data-scrolled');
      }
    });
  }
}

let navigation = new Navigation();
let view = new View();
let scroll = new Scroll();
