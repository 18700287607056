
//PLUGINS ----------------

//Don't fire function immediately after event

var waitForFinalEvent = (function () {
  var timers = {};
  return function (callback, ms, uniqueId) {
    if (!uniqueId) {
      uniqueId = "Don't call this twice without a uniqueId";
    }
    if (timers[uniqueId]) {
      clearTimeout (timers[uniqueId]);
    }
    timers[uniqueId] = setTimeout(callback, ms);
  };
})();

//Selectors shorthand

window.$ = function(selector) {

  var selectorType = 'querySelector';

  if (selector.indexOf('#') === 0) {
    selectorType = 'getElementById';
    selector = selector.substr(1, selector.length);
  }
  return document[selectorType](selector);
};

//Scroller

function scrollIt( destination, duration ) {
  var from = window.scrollY,
    interval = 15,
    thisStep = 0,
    stepSize = 1 / Math.round( duration / interval );
    difference = Math.abs(destination - from),
    easing = function(t) {
      return t<.5 ? 16*t*t*t*t*t : 1+16*(--t)*t*t*t*t
    },
    isPositive = true;
  (function updateScroll() {
    thisStep += 1;
    isPositive = destination > window.scrollY ? true : false;
    newPos = isPositive ? from + difference * easing( thisStep * stepSize ) : from - difference * easing( thisStep * stepSize );
    window.scrollTo( 0, newPos );
    Math.round( newPos ) != destination ? setTimeout(updateScroll, interval) : void(0);
  })();
}

//AJAX Post

function postAjax(url, data, success) {
  var params = typeof data == 'string' ? data : Object.keys(data).map(
      function(k){ return encodeURIComponent(k) + '=' + encodeURIComponent(data[k]) }
    ).join('&');

  var xhr = window.XMLHttpRequest ? new XMLHttpRequest() : new ActiveXObject("Microsoft.XMLHTTP");
  xhr.open('POST', url);
  xhr.onreadystatechange = function() {
    if (xhr.readyState>3 && xhr.status==200) { success(xhr.responseText); }
  };
  xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
  xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
  xhr.send(params);
  return xhr;
}

//PLUGINS END ----------------
